import React, { useState } from 'react';
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "./ui/dropdown-menu";
import { Separator } from "./ui/separator";
import { cn } from "../lib/utils";
import { useAuth } from '../contexts/AuthContext';
import { Menu, X } from 'lucide-react';
import { Button } from "./ui/button";

const TopNavbar = ({ activeView, setActiveView }) => {
  const { user, logout } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuItemClick = (view) => {
    setActiveView(view);
    localStorage.setItem('activeView', view);
    setIsMenuOpen(false);
  };

  const menuItems = [
    { name: 'Dashboard', view: 'dashboard' },
    { name: 'Learn', view: 'learn' },
    { name: 'Customers', view: 'customers' },
    { name: 'Courses', view: 'courses' },
    { name: 'Users', view: 'users' },
    { name: 'Enrollments', view: 'enrollments' },
  ];

  return (
    <div className="w-full backdrop-blur-md bg-background/80 sticky top-0 z-50">
      <div className="max-w-[calc(100%-2rem)] mx-auto">
        <div className="flex justify-between items-center py-4">
          <div className="flex items-center pl-4">
            <img src="/logo.svg" alt="Octix" className="w-24 h-8" />
          </div>
          
          {/* Desktop and Tablet Menu */}
          <div className="hidden md:flex space-x-4">
            {menuItems.map((item) => (
              <span
                key={item.view}
                onClick={() => handleMenuItemClick(item.view)}
                className={cn(
                  "px-3 py-2 text-sm font-medium rounded-md transition-colors cursor-pointer",
                  activeView === item.view ? "text-primary" : "text-muted-foreground hover:text-primary"
                )}
              >
                {item.name}
              </span>
            ))}
          </div>
          
          {/* User Profile and Mobile Menu */}
          <div className="flex items-center space-x-2 pr-4">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Avatar className="cursor-pointer focus:outline-none focus:ring-0 focus:ring-offset-0">
                  <AvatarImage src={user.avatarUrl} alt={user.username} />
                  <AvatarFallback>{user.username.charAt(0).toUpperCase()}</AvatarFallback>
                </Avatar>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-48 md:w-56">
                <DropdownMenuLabel>My Account</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem>Profile</DropdownMenuItem>
                <DropdownMenuItem>Settings</DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={logout}>Logout</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            
            {/* Mobile Menu Button */}
            <Button
              variant="ghost"
              className="md:hidden"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </Button>
          </div>
        </div>
      </div>
      
      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="md:hidden">
          {menuItems.map((item) => (
            <div
              key={item.view}
              onClick={() => handleMenuItemClick(item.view)}
              className={cn(
                "px-4 py-2 text-sm font-medium transition-colors cursor-pointer",
                activeView === item.view ? "bg-primary/10 text-primary" : "text-muted-foreground hover:bg-primary/5 hover:text-primary"
              )}
            >
              {item.name}
            </div>
          ))}
        </div>
      )}
      <Separator />
    </div>
  );
};

export default TopNavbar;