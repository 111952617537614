import React, { Suspense, lazy } from 'react';

const Dashboard = lazy(() => import('../pages/Dashboard'));
const CourseDesigner = lazy(() => import('../pages/CourseDesigner'));
const Customers = lazy(() => import('../pages/Customers'));
const Users = lazy(() => import('../pages/Users'));
const Learn = lazy(() => import('../pages/Learn'));
const Enrollments = lazy(() => import('../pages/Enrollments'));

const MainContent = ({ activeView }) => {
  const renderContent = () => {
    switch (activeView) {
      case 'dashboard':
        return <Dashboard />;
      case 'courses':
        return <CourseDesigner />;
      case 'customers':
        return <Customers />;
      case 'users':
        return <Users />;
      case 'learn':
        return <Learn />;
      case 'enrollments':
        return <Enrollments />;
      default:
        return (
          <div className="p-8">
            <h2 className="text-2xl font-semibold mb-6">{activeView.charAt(0).toUpperCase() + activeView.slice(1)}</h2>
            <p>Content for {activeView} goes here.</p>
          </div>
        );
    }
  };

  return (
    <Suspense fallback={null}>
      {renderContent()}
    </Suspense>
  );
};

export default MainContent;