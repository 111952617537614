import { ThemeProvider } from "next-themes"
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Login from './components/Login';
import TopNavbar from './components/TopNavbar';
import MainContent from './components/MainContent';
import React, { useState } from 'react';

const App = () => {
  const [activeView, setActiveView] = useState(() => {
    return localStorage.getItem('activeView') || 'dashboard';
  });
  const { user, loading } = useAuth();

  const updateActiveView = (newView) => {
    setActiveView(newView);
    localStorage.setItem('activeView', newView);
  };

  if (loading) {
    return null; // Return nothing while loading
  }

  if (!user) {
    return <Login />;
  }

  return (
    <div className="min-h-screen bg-background text-foreground">
      <TopNavbar activeView={activeView} setActiveView={updateActiveView} />
      <main className="container mx-auto px-4 pt-4">
        <MainContent activeView={activeView} />
      </main>
    </div>
  );
}

const AppWithAuth = ({ Component, pageProps }) => (
  <ThemeProvider attribute="class" defaultTheme="dark" enableSystem={false} storageKey="theme">
    <AuthProvider>
      <main className="dark">
        <App Component={Component} pageProps={pageProps} />
      </main>
    </AuthProvider>
  </ThemeProvider>
);

export default AppWithAuth;