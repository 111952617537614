import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Card, CardContent } from "./ui/card";
import { Input } from "./ui/input";
import { Button } from "./ui/button";

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login } = useAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (login(username, password)) {
      setError('');
    } else {
      setError('Invalid credentials');
    }
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-black text-white">
      {/* Left side - hidden on mobile */}
      <div className="hidden md:flex md:w-1/2 flex-col justify-between p-10 bg-zinc-900">
        <div className="text-xl font-bold">
          <img src="/logo.svg" alt="Octix" className="h-16" />
        </div>
        <div>
          <blockquote className="text-2xl font-light mb-4 leading-relaxed">
            "This library has saved me countless hours of work and helped me deliver stunning designs to my clients faster than ever before."
          </blockquote>
          <cite className="text-gray-400">Sofia Davis</cite>
        </div>
      </div>

      {/* Right side */}
      <div className="w-full md:w-1/2 bg-black flex flex-col items-center justify-center p-6 md:p-10">
        {/* Logo for mobile view */}
        <div className="md:hidden mb-8">
          <img src="/logo.svg" alt="Octix" className="h-12" />
        </div>
        
        <Card className="w-full max-w-[400px] bg-black text-white shadow-none border-0">
          <CardContent className="space-y-6">
            <div className="space-y-2">
              <h1 className="text-3xl font-semibold">Log in to your account</h1>
              <p className="text-gray-400">Enter your credentials below to access your account</p>
            </div>
            <form onSubmit={handleSubmit} className="space-y-4">
              <Input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Username"
                className="bg-zinc-900 border-zinc-700 focus:border-white transition-colors"
              />
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                className="bg-zinc-900 border-zinc-700 focus:border-white transition-colors"
              />
              {error && (
                <div className="text-red-500 text-sm">{error}</div>
              )}
              <Button type="submit" className="w-full bg-white text-black hover:bg-gray-200 transition-colors">
                Sign In
              </Button>
            </form>
            <div className="text-xs text-gray-400 text-center">
              By clicking continue, you agree to our{' '}
              <button onClick={() => {/* Handle Terms of Service click */}} className="underline hover:text-white transition-colors">
                Terms of Service
              </button> and{' '}
              <button onClick={() => {/* Handle Privacy Policy click */}} className="underline hover:text-white transition-colors">
                Privacy Policy
              </button>.
            </div>
          </CardContent>
        </Card>
      </div>
      {/* Thin line between the two sides */}
      <div className="hidden md:block absolute left-1/2 top-0 bottom-0 w-px bg-zinc-800"></div>
    </div>
  );
};

export default Login;